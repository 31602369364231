<template>
  <div class="user-form p-20">
    <form @submit.prevent="onSubmit">
      <div class="user-form-field">
        <label class="user-form-label">
          <app-icon
            icon="person"
            :color="
              invalidFio && !isInversionTheme
                ? 'red'
                : '#0095da'
            "
            :size="24"
          ></app-icon>
        </label>
        <input
          type="text"
          v-model.trim="fio"
          @input="setFio($event.target.value)"
          class="user-form-input"
          placeholder="Имя (имя, отчество)"
          :class="{ invalid: invalidFio }"
        />
        <small
          v-if="$v.fio.$dirty && !$v.fio.required"
          class="user-form-error"
        >
          Поле с именем не должно быть пустым
        </small>
        <small
          v-else-if="$v.fio.$dirty && !$v.fio.letters"
          class="user-form-error"
        >
          Имя должно быть написано буквами кириллицы
        </small>
      </div>
      <div class="user-form-field">
        <label class="user-form-label">
          <app-icon
            icon="phone"
            :color="
              invalidPhone && !isInversionTheme
                ? 'red'
                : '#0095da'
            "
            :size="24"
          ></app-icon>
        </label>
        <input
          type="tel"
          class="user-form-input"
          v-model.trim="phone"
          @input="setPhone($event.target.value)"
          placeholder="Введите номер телефона"
          :class="{ invalid: invalidPhone }"
        />
        <small
          v-if="$v.phone.$dirty && !$v.phone.required"
          class="user-form-error"
        >
          Поле с телефоном не должно быть пустым
        </small>
        <small
          v-else-if="
            $v.phone.$dirty && !$v.phone.phoneNumber
          "
          class="user-form-error"
        >
          Номер должен быть записан цифрами
        </small>
      </div>
      <button class="user-form-button mt-10" type="submit">
        Продолжить
      </button>
    </form>
  </div>
</template>
<script>
import AppIcon from '@/components/core/AppIcon'
import types from '@/store/types'
import { mapActions, mapState } from 'vuex'
import { required, helpers } from 'vuelidate/lib/validators'

const letters = helpers.regex('alpha', /^[ёа-я\s-]*$/i)
const phoneNumber = helpers.regex('int', /^\+?\d{1,}$/)

export default {
  components: {
    AppIcon,
  },
  data: () => ({
    fio: '',
    phone: '',
    source: location.host,
  }),
  computed: {
    ...mapState([types.state.opened]),
    invalidFio() {
      return (
        (this.$v.fio.$dirty && !this.$v.fio.letters) ||
        (this.$v.fio.$dirty && !this.$v.fio.required)
      )
    },
    invalidPhone() {
      return (
        (this.$v.phone.$dirty &&
          !this.$v.phone.phoneNumber) ||
        (this.$v.phone.$dirty && !this.$v.phone.required)
      )
    },
    isInversionTheme() {
      return (
        this.$store.getters.getCurrentTheme ===
        'theme-inversion'
      )
    },
  },
  validations: {
    fio: { required, letters },
    phone: { required, phoneNumber },
  },
  methods: {
    ...mapActions([types.actions.register]),
    onSubmit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const user = {
        fio: this.fio,
        phone: this.phone,
        source: this.source,
      }
      this.register(user)
    },
    setFio(value) {
      this.fio = value
      this.$v.fio.$touch()
    },
    setPhone(value) {
      this.phone = value
      this.$v.phone.$touch()
    },
  },
}
</script>
